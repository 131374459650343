<template lang="pug">
.page 
  div jobjam homepage

</template>

<script setup>
import { inject, onMounted } from 'vue'

import { useRouter } from 'vue-router'
import { isLogin } from '@/provider/account'
// const router = useRouter()

const { fetch, message, router } = inject('global')

onMounted(() => {
  if (!localStorage.token) {
    message.warning('请先登陆')
    router.replace('/account/login')
  } else {
    router.push('/job')
  }
})
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
